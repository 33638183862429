<template lang="pug">
include ../../../configs/template
div
  TableV2(
    :isViewAdd="false"
    :headers="headers"
    :items="items.results"
    :pageCount="items.count"
    :currentPage="items.current"
    :querySearch="getPriceForAdvTraining"
    :isLoading="isLoading"
    isHiddenTableSearch
    componentInfo="BackOfficeVnzTable"
    showExpand,
    :actions="actions"
  )
    Add(slot="AddBlock")
  ModalWindow(ref="editTypeAccessEducationIstitution")
    template(#title) {{$t('editTypeSubscription')}}
    template(#body)
      +select-validation('body.access_type','listInstitutionType','accessType','nameLang','["required"]')
    template(#footer)
      v-btn(color="error" outlined @click="closeModal").mr-4 {{$t('btn.cancel')}}
      v-btn(color="success" @click="saveAccessType" :loading="isLoading") {{$t('btn.save')}}
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { changeTypeAccessEducationInstitution } from '@/mixins/validationRules'

export default {
  name: 'vnz-backoffice-groups',
  components: {
    Add: () => import('./Add.vue')
  },
  data () {
    return {
      headers: [
        { value: 'id', text: this.$t('id'), sortable: false },
        { value: 'name_ukr', text: this.$t('nameInstitution'), sortable: false },
        { value: 'access_type', text: this.$t('accessType'), sortable: false },
        { text: '', value: 'data-table-expand', sortable: false },
        { value: 'event', text: this.$t('actions'), class: 'mw-0', sortable: false }

      ],
      newDoc: false,
      actions: [
        {
          id: 1,
          action: (item) => {
            this.$refs.editTypeAccessEducationIstitution.changeModalState(true)
            Object.keys(this.body).forEach(key => (this.body[key] = item[key]))
            this.education_institution = item
          },
          color: 'black',
          name: 'mdi-cog-sync-outline',
          tooltip: 'tooltip.editSubscription',
          buttonOptions: { xSmall: false, icon: true }
        }
      ],
      body: {
        access_type: null
      },
      education_institution: null
    }
  },
  computed: {
    ...mapState({
      items: state => state.backoffice.vnz,
      isLoading: state => state.backoffice.isLoading,
      listInstitutionType: state => state.directory.institutionTypes,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    })
  },
  validations () { return changeTypeAccessEducationInstitution() },
  methods: {
    ...mapActions(['getPriceForAdvTraining', 'updateAccessTypeForAdvTraining']),
    closeModal () {
      if (this.$refs.editTypeAccessEducationIstitution) this.$refs.editTypeAccessEducationIstitution.changeModalState(false)
      this.$v.$reset()
      this.body.access_type = null
    },
    saveAccessType () {
      this.$swal({
        icon: 'warning',
        title: this.$t('notify.title.warning'),
        text: this.$t('Do you sure that you want change type subscription'),
        buttons: [this.$t('no'), this.$t('yes')]
      }).then(async confirm => {
        if (confirm) {
          await this.updateAccessTypeForAdvTraining({ id: this.education_institution.id, body: this.body })
          await this.getPriceForAdvTraining({ ...this.$route.query })
          this.$notification.success('notify.success.changedTypeSubscription')
          this.closeModal()
        }
      })
    }
  }
}
</script>
